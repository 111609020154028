import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, OnInit, signal } from '@angular/core';
import { DashboardService } from '../../../../../core/services/dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import Highcharts from 'highcharts/highcharts-gantt';
import { HighchartsChartModule } from 'highcharts-angular';
import HC_exporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';

NoDataToDisplay(Highcharts);
HC_exporting(Highcharts);

@Component({
  selector: 'app-client-po-performance-charts',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    HighchartsChartModule,
    MatProgressSpinnerModule
],
  templateUrl: './client-po-performance-charts.component.html',
  styleUrl: './client-po-performance-charts.component.scss'
})
export class ClientPoPerformanceChartsComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;  
  chartHeight = "65vh"
  chartWidth = "80vw"
  loadingSpinnerPercentageTop = "40%"
  loadingSpinnerPercentageLeft = "46%"
  darkMode:boolean = false;
  selectedDashboardTimeFrameNumberOfDays:number|undefined;
  
  dashboardService = inject(DashboardService)
  selectedUserType:string = this.dashboardService.userTypes[0];
  showLoadingSpinner = true;
  updateCharts = false;

  clientPOPerformanceGanttChartOptions = signal<Highcharts.Options>(
      {
          chart: {
              backgroundColor: 'transparent',
              style: { paddingRight: '20px' },
              height: null, // Remove fixed height
              width: null,  // Allow chart to scale with container width
              // events: {
              //     load: function() {
              //         const chart = this;
              //         window.addEventListener('resize', function() {
              //             chart.setSize(null, chartHeight, false);
              //         });
              //     }
              // }
          },
  
  
          title: { text: 'Purchase Orders (Top 5) and PO Details Hierarchy', align: 'left', style: { fontSize: '15px' } },
          yAxis: {
              type: 'treegrid',
              //uniqueNames: true,
              gridLineWidth: 0,
              labels: {
                  style: {
                      color: 'black' // Use default color
                  }
              }
          },
          navigator: {
              enabled: true,
              //liveRedraw: true,
              series: {
                  type: 'gantt',
                  //pointPlacement: 0.5,
                  pointPadding: 0.25,
                  accessibility: { enabled: false }
              },
              /*
              scrollbar: {
                  enabled: true
              }
                  */
          },
          rangeSelector: {
              enabled: true, selected: 4,
              buttons: [
                  { type: 'month', count: 1, text: '1 M' },
                  { type: 'month', count: 3, text: '3 M' },
                  { type: 'month', count: 6, text: '6 M' },
                  { type: 'year', count: 1, text: '1 Y' },
                  { type: 'all', text: 'All' }
              ]
          },
          credits: { enabled: false },
          legend: {
              backgroundColor:'white'
          },
        exporting: {
          enabled: true,
          buttons: {
              contextButton: {
                  menuItems:["viewFullscreen","printChart"]
              }
          }
        },
        lang: 
        {
            noData: this.dashboardService.noDataForThisTimeFrameMessage
        },
        noData: this.dashboardService.noDataMessageStyle
    }
  )

  constructor(private dialog:MatDialog) {
    effect(() => {
      if(this.dashboardService.selectedUserType().toUpperCase()=="CLIENT" && 
      (this.darkMode != this.dashboardService.darkMode() 
      || this.selectedUserType != this.dashboardService.selectedUserType()
      || this.selectedDashboardTimeFrameNumberOfDays != this.dashboardService.selectedDashboardTimeFrameNumberOfDays())
    ) {
        this.selectedUserType = this.dashboardService.selectedUserType();
        this.darkMode = this.dashboardService.darkMode();
        this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays();
        this.getClientPOPerformanceGanttChartOptions();
      }
    }
    );
  }

  ngOnInit(): void {
    this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays();
    this.getClientPOPerformanceGanttChartOptions()
  }

  getClientPOPerformanceGanttChartOptions() {
    this.updateCharts = false;
    this.showLoadingSpinner = true;
    this.dashboardService.getClientPOPerformanceGanttChart().subscribe({
      next:(data:any)=> {
        let POPerformanceGanttChartOptionsWithData = this.clientPOPerformanceGanttChartOptions()
        const today = new Date();
        const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
        const seriesData = data.map((parentEntry: any) => {
            const parent = {
                id: parentEntry.id,
                name: `PO: ${parentEntry.id} - ${parentEntry.vendor}`,
                start: this.dashboardService.parseDateString(parentEntry.start),
                end: this.dashboardService.parseDateString(parentEntry.end),
                collapsed: true,
                classificationRate: parentEntry.classificationRate,
                color: this.dashboardService.getGradientColor(parentEntry.classificationRate)
            };
            const children = parentEntry.children.map((child: any) => ({
                id: child.id,
                name: child.id,
                start: this.dashboardService.parseDateString(child.start),
                end: this.dashboardService.parseDateString(child.end),
                parent: parentEntry.id,
                color: this.dashboardService.getStatusColor(child.productStatus),
                productStatus: child.productStatus
            }));
            return [parent, ...children];
        }).flat();

        let minDate = new Date(Math.min(...seriesData.map((item:any) => item.start))); // Get the earliest start date
        let maxDate = new Date(Math.max(...seriesData.map((item:any) => item.end))); // Get the latest end date
        minDate.setUTCMonth(minDate.getUTCMonth() - 2); // Subtract 2 months from the min date
        maxDate.setUTCMonth(maxDate.getUTCMonth() + 2); //
        let minTime = minDate.getTime();
        let maxTime = maxDate.getTime();

        POPerformanceGanttChartOptionsWithData.series = [{
            name: 'Purchase Orders',
            type: 'gantt',
            data: seriesData,
            dataLabels: {
                enabled: true,
                formatter: function () {
                    if ((this.point as any).productStatus) {
                        return (this.point as any).productStatus;
                    } else if (typeof (this.point as any).classificationRate !== 'undefined') {
                        return `PO Classification rate: ${(this.point as any).classificationRate}%`;
                    } else {
                        return '';
                    }
                },
                style: { color: 'black', textOutline: 'none', fontWeight: 'bold', fontSize: '16px' },
                align: 'center', verticalAlign: 'middle'
            }
        }],
        POPerformanceGanttChartOptionsWithData.xAxis = {
            min: minTime, max: maxTime,
            type: 'datetime',
            plotLines: [{
                value: todayUTC, width: 2, dashStyle: 'Solid', zIndex: 10,
                label: {
                    text: `Today [${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}]`,
                    align: 'left', rotation: 0,
                    style: {  color:'gray',fontWeight: 'bold' }
                }
            }],
            labels: {
                style: {
                    color: 'black' // Use default color
                }
            },
            events: {
                afterSetExtremes: function (e) {
                    const chart = this.chart;
                    const seriesData = chart.series[0].data;
                    let minDate = new Date(Math.min(...seriesData.map((item:any) => item.start))); // Get the earliest start date
                    let maxDate = new Date(Math.max(...seriesData.map((item:any) => item.end))); // Get the latest end date
                    minDate.setUTCMonth(minDate.getUTCMonth() - 2); // Subtract 2 months from the min date
                    maxDate.setUTCMonth(maxDate.getUTCMonth() + 2); //
                    let minTime = minDate.getTime();
                    let maxTime = maxDate.getTime();
                    chart.xAxis[0].setExtremes(minTime, maxTime, true, false);
                }
            }
        };

        this.clientPOPerformanceGanttChartOptions.set(POPerformanceGanttChartOptionsWithData)
      },
      error:() => {
        if(!this.dashboardService.errorMessageDisplayed()) {
          this.dashboardService.errorMessageDisplayed.set(true);
          const errorDialog = this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
          errorDialog.afterClosed().subscribe(() => {
              this.dashboardService.errorMessageDisplayed.set(false)
            }
          );
          
        }
      },
      complete:() => {

        this.clientPOPerformanceGanttChartOptions.set(this.dashboardService.applyChangeToDarkModeToChartTextColor(this.clientPOPerformanceGanttChartOptions()));
        this.updateCharts = true;
        this.showLoadingSpinner = false;
       
      }
    })
  }
}
