import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../features/settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import Highcharts from 'highcharts';
import { IDashboardKpiItem } from '../models/IDashboardKpiItem';
import { IDashboardChartTimeFrame } from '../models/IDashboardChartTimeFrame';

type StatusColor = {
    [key:string]:string
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  baseUrl = environment.apiUrl + "/api/"
  darkMode = signal<boolean>(false);
  errorMessageDisplayed = signal<boolean>(false); // flag to prevent multiple error message dialogs displaying
  readonly clientUserTabLabels = ["Overall Performance","Performance by Vendor","Performance by PO"];
  readonly vendorUserTabLabels = ["Overall Performance","Performance by PO"];
  readonly customsTeamTabLabels = ["Overall Performance"];
  readonly userTypes = ["Client","Vendor","Customs Team"];
  readonly statusColors:StatusColor = {
    'Pending Information': 'rgb(211,211,211)',
    'Under Vendor Review': 'rgb(44,175,254)',
    'Under Customs Review': 'orange',
    'Approved and Classified': 'rgb(0,226,114)',
    'Revision Required': 'red'
    };
readonly noDataForThisTimeFrameMessage = "Data for this time frame isn't available yet. Please check back soon!"
readonly noDataMessageStyle = {
    useHTML:true,
    style: {
        width:280,
        textOverflow:'wrap',
        textAlign:'center'
    }
};
  selectedUserType = signal<string>(this.userTypes[0]);
  readonly dashboardTimeFrames:IDashboardChartTimeFrame[] = [
    {
        label:"Last 24 hours",
        numberOfDays:1
    },
    {
        label:"Last 7 days",
        numberOfDays:7
    },
    {
        label:"Last 30 days",
        numberOfDays:30
    }];
    selectedDashboardTimeFrameNumberOfDays = signal<number>(this.dashboardTimeFrames[0].numberOfDays);

  constructor(private dialog:MatDialog,private httpClient:HttpClient) { }

  getClientOverallPerformanceByStatusDonutChartOptions() {
    return this.httpClient.get(this.baseUrl + `clientDashboard/overall-performance-by-status-donut-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);
  }

  getClientItemTypeGroupPerformanceByStatusTop10StackedColumnChartOptions() {
    return this.httpClient.get(this.baseUrl + `clientDashboard/item-type-group-performance-by-status-top-10-stacked-column-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);
    }

  getClientItemAttributePerformancebyStatusTop10StackedBarChartOptions() {

    return this.httpClient.get(this.baseUrl + `clientDashboard/item-attribute-performance-by-status-top-10-stacked-bar-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);
}


  getClientCumulativePerformanceByStatusLast7DaysSplineChartOptions() {
    return this.httpClient.get(this.baseUrl + `clientDashboard/cumulative-performance-by-status-spline-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);
    }

  getClientVendorPerformanceByStatusTop10StackedBarChart() {
    return this.httpClient.get(this.baseUrl + `clientDashboard/vendor-performance-by-status-top-10/${this.selectedDashboardTimeFrameNumberOfDays()}`);
  }

  getClientPOPerformanceGanttChart() {
    return this.httpClient.get(this.baseUrl + `clientDashboard/gantt-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);
}

  getVendorOverallPerformanceBulletChartOptions() {
    return this.httpClient.get(this.baseUrl + `VendorDashboard/overall-performance-bullet-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);
  }

  getVendorCumulativePerformanceByStatusLast7DaysSplineChartOptions() {
    return this.httpClient.get(this.baseUrl + `VendorDashboard/cumulative-performance-by-status-spline-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);

}

  getVendorItemTypeGroupPerformanceByStatusTop10ColumnChartOptions() {

    return this.httpClient.get(this.baseUrl + `VendorDashboard/item-type-group-performance-by-status-top-10-column-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);

    }

  getVendorPOPerformanceGanttChartOptions() {
    return this.httpClient.get(this.baseUrl + `VendorDashboard/gantt-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);
  }

  getVendorItemAttributePerformanceByStatusTop10BarChartOptions() {

    return this.httpClient.get(this.baseUrl + `VendorDashboard/item-attribute-performance-by-status-top-10-bar-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);
}

  getVendorItemTypesPerformanceByStatusTop10BarChartOptions() {

    return this.httpClient.get(this.baseUrl + `VendorDashboard/item-types-performance-by-status-top-10-bar-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);
}

  getCustomsTeamOverallPerformanceByStatusDonutChartOptions() {

    return this.httpClient.get(this.baseUrl + `CustomsTeamDashboard/overall-performance-by-status-donut-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);
  }

  getCustomsTeamItemTypeGroupByStatusTop10StackedColumnChartOptions() {

    return this.httpClient.get(this.baseUrl + `CustomsTeamDashboard/item-type-group-performance-by-status-top-10-stacked-bar-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);
  }

  getCustomsTeamItemTypePerformanceByStatusTop10StackedBarChartOptions() {

    return this.httpClient.get(this.baseUrl + `CustomsTeamDashboard/item-type-performance-by-status-top-10-stacked-bar-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);
    }

  getCustomsTeamItemAttributeByStatusTop10StackedBarChartOptions() {

    return this.httpClient.get(this.baseUrl + `CustomsTeamDashboard/item-attribute-performance-by-status-top-10-stacked-bar-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);
}

  getCustomsTeamCumulativePerformanceByStatusLast7DaysAreaChartOptions() {
    return this.httpClient.get(this.baseUrl + `CustomsTeamDashboard/cumulative-performance-by-status-area-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);
    }

    getCustomTeamKpiItems() {
        return this.httpClient.get(this.baseUrl + `CustomsTeamDashboard/kpi-chart/${this.selectedDashboardTimeFrameNumberOfDays()}`);
    }

  applyStatusColors(data: any[]) {
    return data.map((dataItem:any) => {
        const color = this.statusColors[dataItem.name] || dataItem.color;
        return { ...dataItem, color };
    });
    }

    mapSeriesWithColors(series:any) {
        return series.map((seriesItem:any) => {
            let color;
            if (seriesItem.name === 'Under Customs Review') color = "rgb(44,175,254)";
            else if (seriesItem.name === 'Approved and Classified') color = "rgb(0,226,114)";
            else if (seriesItem.name === 'Revision Required') color = 'red';
            return { ...seriesItem, color };
        });
    }

    parseDateString(dateString:string) {
        const [year, month, day] = dateString.split('-').map(Number);
        const fullYear = year < 100 ? 2000 + year : year;
        return Date.UTC(fullYear, month - 1, day);
    }

    getStatusColor(status:string) {
        switch (status) {
            case 'Approved and Classified': return "rgb(0,226,114)";
            case 'Under Customs Review': return "rgb(44,175,254)";
            case 'Revision Required': return 'red';
            default: return '#007bff';
        }
    }

    getGradientColor(rate:number) {
        const validRate = Math.max(0, Math.min(rate, 100));
        const percentage = validRate / 100;
        return {
            linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
            stops: [
                [0, 'rgb(0,226,114)'],
                [percentage, 'rgb(0,226,114)'],
                [percentage, 'lightgray'],
                [1, 'lightgray']
            ]
        };
    }

  applyChangeToDarkModeToChartTextColor(chartOptions: any) {
    const textColor = this.darkMode() ? "white" : "black";

    const applyTextColor = (obj: any, property: string) => {
        if (obj && obj[property]) {
            obj[property].style = obj[property].style || {};
            obj[property].style.color = textColor;
        }
    };

    if (chartOptions) {
        applyTextColor(chartOptions, 'title');
        if (chartOptions.xAxis) {
            applyTextColor(chartOptions.xAxis, 'labels');
            applyTextColor(chartOptions.xAxis, 'title');
        }
        if (chartOptions.yAxis) {
            applyTextColor(chartOptions.yAxis, 'labels');
            applyTextColor(chartOptions.yAxis, 'title');
        }
        if (chartOptions.plotOptions && chartOptions.plotOptions.pie) {
            const pie = chartOptions.plotOptions.pie;
            pie.dataLabels = pie.dataLabels || {};
            pie.dataLabels.style = pie.dataLabels.style || {};
            pie.dataLabels.style.color = textColor;
        }

        return chartOptions
    }
  }



}
