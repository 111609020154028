import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import Highcharts from 'highcharts/highcharts-gantt';
import { HighchartsChartModule } from 'highcharts-angular';
import HC_exporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { DashboardService } from '../../../../../../core/services/dashboard.service';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../../../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
NoDataToDisplay(Highcharts);
HC_exporting(Highcharts);

@Component({
  selector: 'app-item-types-performance-chart',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    HighchartsChartModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './item-types-performance-chart.component.html',
  styleUrl: './item-types-performance-chart.component.scss'
})
export class ItemTypesPerformanceChartComponent {
     Highcharts: typeof Highcharts = Highcharts;
      chartHeight = "30vh"
      chartWidth = "100%"
      loadingSpinnerPercentageTop = "40%"
      loadingSpinnerPercentageLeft = "42%"
      darkMode:boolean = false;
      dashboardService = inject(DashboardService)
      selectedUserType:string = this.dashboardService.userTypes[1];
      selectedDashboardTimeFrameNumberOfDays:number|undefined;
  
      showLoadingSpinner = true;
      updateCharts:boolean = false;
  
      chartOptions = signal<Highcharts.Options>(
        {
            chart: { type: 'bar', backgroundColor: 'transparent' },
            title: { text: '(Top 10) Item Types performance by Status', align: 'left', style: { fontSize: '15px' } },
            yAxis: {
                min: 0,
                title: { text: 'Count' },
                labels: { enabled: false },
                gridLineWidth: 0,
                stackLabels: {
                    enabled: true,
                    formatter: function () { return Highcharts.numberFormat(this.total, 0); },
                    style: { fontWeight: 'bold', color: 'black' }
                }
            },
            legend: {
                align: 'right',
                verticalAlign: 'top',
                y: 1,
                floating: false,
                backgroundColor: 'white',
                //borderColor: null,
                borderWidth: 0,
                shadow: false,
                itemStyle: { fontSize: '10px' }
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            /*
            plotOptions: {
                [chartType]: {
                    stacking: 'normal',
                    dataLabels: { enabled: true }
                }
            },
            */
            plotOptions: { bar: { stacking: 'normal', dataLabels: { enabled: true } } },
            credits: { enabled: false },
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems:["viewFullscreen","printChart"]
                    }
                }
            },
            lang: 
            {
                noData: this.dashboardService.noDataForThisTimeFrameMessage
            },
            noData: this.dashboardService.noDataMessageStyle
        }
      )
  
      constructor(private dialog:MatDialog) {
        effect(() => {
          if(this.dashboardService.selectedUserType().toUpperCase()=="VENDOR" && 
          (this.darkMode != this.dashboardService.darkMode() 
          || this.selectedUserType != this.dashboardService.selectedUserType()
          || this.selectedDashboardTimeFrameNumberOfDays != this.dashboardService.selectedDashboardTimeFrameNumberOfDays())
        ) {
            this.selectedUserType = this.dashboardService.selectedUserType();
            this.darkMode = this.dashboardService.darkMode();
            this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays();
            this.getChartOptions();
          }
        })
      }
    
      ngOnInit(): void {
        this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays()
        this.getChartOptions();
      }
    
      getChartOptions() {
        this.updateCharts = false;
        this.showLoadingSpinner = true;
        this.dashboardService.getVendorItemTypesPerformanceByStatusTop10BarChartOptions().subscribe({
          next:(data:any)=> {
            let chartOptionsWithData = this.chartOptions();
    
            let seriesWithColors = this.dashboardService.mapSeriesWithColors(data.series);
    
            seriesWithColors = seriesWithColors.map((seriesItem:any) => {
                const filteredData = seriesItem.data.filter((point: number) => point !== 0);
                return { ...seriesItem, data: filteredData };
            });
    
            chartOptionsWithData.series = seriesWithColors;
            chartOptionsWithData.xAxis = {
                categories: data.categories,
                title: { text: 'Item Type' },
                gridLineWidth: 1,
                labels: {
                    style: {
                        color: 'black' // Use default color
                    }
                }
            };
    
    
            this.chartOptions.set(chartOptionsWithData);
          },
          error:() => {
            if(!this.dashboardService.errorMessageDisplayed()) {
              this.dashboardService.errorMessageDisplayed.set(true);
              const errorDialog = this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
              errorDialog.afterClosed().subscribe(() => {
                  this.dashboardService.errorMessageDisplayed.set(false)
                }
              );
              
            }
          },
          complete:() => {
            this.chartOptions.set(this.dashboardService.applyChangeToDarkModeToChartTextColor(this.chartOptions()));
            this.updateCharts = true;
            this.showLoadingSpinner = false;
          }
        })
      }
}
