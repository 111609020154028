<mat-card *ngIf="chartOptions()" [ngClass]="darkMode ? 'chart-card darkModeEnabled':'chart-card darkModeDisabled'" >
    <mat-card-content>
        <div *ngIf="this.showLoadingSpinner" [style.minHeight]="'300px'">
            <div class="mat-spinner-container" [style.top]="'30%'" [style.left]="'48%'">
                <mat-spinner></mat-spinner>
            </div>
        </div>
        <div *ngIf="!this.showLoadingSpinner">
            <highcharts-chart 
                *ngIf="chartOptions()"
                [Highcharts]="Highcharts"
                [options]="chartOptions()"
                [(update)]="updateCharts"
                [style.width]="chartWidth" 
                [style.height]= "'300px'"
                [style.display]="'block'"
                ></highcharts-chart>
        </div>
    </mat-card-content>
</mat-card>
