import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, signal } from '@angular/core';
import { DashboardService } from '../../../../../core/services/dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import Highcharts from 'highcharts/highcharts-gantt';
import { HighchartsChartModule } from 'highcharts-angular';
import HC_exporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

NoDataToDisplay(Highcharts);
HC_exporting(Highcharts);

@Component({
  selector: 'app-client-vendor-performance-charts',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    HighchartsChartModule,
    MatProgressSpinnerModule
],
  templateUrl: './client-vendor-performance-charts.component.html',
  styleUrl: './client-vendor-performance-charts.component.scss'
})
export class ClientVendorPerformanceChartsComponent {
   Highcharts: typeof Highcharts = Highcharts;
    chartHeight = "65vh"
    chartWidth = "80vw"
    loadingSpinnerPercentageTop = "40%"
    loadingSpinnerPercentageLeft = "46%"
    darkMode:boolean = false;
    selectedDashboardTimeFrameNumberOfDays:number|undefined;
    
    dashboardService = inject(DashboardService)
    selectedUserType:string = this.dashboardService.userTypes[0];
  clientVendorPerformanceByStatusTop10StackedBarChartOptions = signal<Highcharts.Options>(
    {
        chart: { type: 'bar', backgroundColor: 'transparent' },
        title: { text: '(Top 10) Performance by Vendor by Status', align: 'left', style: { fontSize: '15px' } },
        yAxis: {
            min: 0, title: { text: 'Count' }, gridLineWidth: 0,
            stackLabels: { enabled: true, formatter: function () { return Highcharts.numberFormat(this.total, 0); }, style: { fontWeight: 'bold', color: 'black' } },
            labels: { enabled: false }
        },
        legend: {
            align: 'right', verticalAlign: 'top', y: 1, floating: false, backgroundColor: 'white', borderColor: '#CCC', borderWidth: 0, shadow: false
        },
        tooltip: { headerFormat: '<b>{point.x}</b><br/>', pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}' },
        plotOptions: {
            bar: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true,
                    formatter: function () { return (this.y && this.y > 0) ? this.y : null; },
                    style: { textOutline: 'none', fontWeight: 'normal' }
                }
            }
        },
        credits: { enabled: false },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    menuItems:["viewFullscreen","printChart"]
                }
            }
        },
        lang: 
        {
            noData: this.dashboardService.noDataForThisTimeFrameMessage
        },
        noData: this.dashboardService.noDataMessageStyle
  
    }
  )

  showLoadingSpinner = true;
  updateCharts = false;
 
  constructor(private dialog:MatDialog) {
    effect(() => {
      if(this.dashboardService.selectedUserType().toUpperCase()=="CLIENT" && 
      (this.darkMode != this.dashboardService.darkMode() 
      || this.selectedUserType != this.dashboardService.selectedUserType()
      || this.selectedDashboardTimeFrameNumberOfDays != this.dashboardService.selectedDashboardTimeFrameNumberOfDays())
    ) {
        this.selectedUserType = this.dashboardService.selectedUserType();
        this.darkMode = this.dashboardService.darkMode();
        this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays();
        this.getClientVendorPerformanceByStatusTop10StackedBarChartOptions();
      }
    }
    );
  }

  ngOnInit(): void {
    this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays();
    this.getClientVendorPerformanceByStatusTop10StackedBarChartOptions();
  }

  getClientVendorPerformanceByStatusTop10StackedBarChartOptions() {
    this.updateCharts = false;
    this.showLoadingSpinner = true;
    this.dashboardService.getClientVendorPerformanceByStatusTop10StackedBarChart().subscribe({
      next:(data:any)=> {
        let clientVendorPerformanceByStatusStackedBarChartOptionsWithData = this.clientVendorPerformanceByStatusTop10StackedBarChartOptions()

                    // Map series data to add colors based on the status name
                const seriesWithColors = this.dashboardService.mapSeriesWithColors(data.series);

                clientVendorPerformanceByStatusStackedBarChartOptionsWithData.series = seriesWithColors;
                clientVendorPerformanceByStatusStackedBarChartOptionsWithData.xAxis = {
                    categories: data.categories, gridLineWidth: 1, title: { text: 'Vendors' },
                        labels: {
                            formatter: function () {
                                return this.value.toString().toUpperCase();
                            },
                        style: {
                            fontWeight: 'normal',
                            color: 'black'
                        }
                    }
                };

                this.clientVendorPerformanceByStatusTop10StackedBarChartOptions.set(clientVendorPerformanceByStatusStackedBarChartOptionsWithData)
      },
      error:() => {
        if(!this.dashboardService.errorMessageDisplayed()) {
          this.dashboardService.errorMessageDisplayed.set(true);
          const errorDialog = this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
          errorDialog.afterClosed().subscribe(() => {
              this.dashboardService.errorMessageDisplayed.set(false)
            }
          );
          
        }
      },
      complete:() => {
        this.clientVendorPerformanceByStatusTop10StackedBarChartOptions.set(this.dashboardService.applyChangeToDarkModeToChartTextColor(this.clientVendorPerformanceByStatusTop10StackedBarChartOptions()));
        this.updateCharts = true;
        this.showLoadingSpinner = false;
      }
    })
  }
}
