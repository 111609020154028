<ng-container *ngIf="this.selectedUserType && this.selectedUserType?.toUpperCase() == 'CLIENT'">
    <ng-container *ngIf="this.tabLabel()?.toUpperCase() == 'OVERALL PERFORMANCE'">
        <app-client-overall-performance-charts></app-client-overall-performance-charts>
    </ng-container>
    <ng-container *ngIf="this.tabLabel()?.toUpperCase() == 'PERFORMANCE BY VENDOR'">
        <app-client-vendor-performance-charts></app-client-vendor-performance-charts>
    </ng-container>
    <ng-container *ngIf="this.tabLabel()?.toUpperCase() == 'PERFORMANCE BY PO'">
        <app-client-po-performance-charts></app-client-po-performance-charts>
    </ng-container>
</ng-container>
<ng-container *ngIf="this.selectedUserType && this.selectedUserType?.toUpperCase() == 'VENDOR'">
    <ng-container *ngIf="this.tabLabel()?.toUpperCase() == 'OVERALL PERFORMANCE'">
        <app-vendor-overall-performance-charts></app-vendor-overall-performance-charts>
    </ng-container>
    <ng-container *ngIf="this.tabLabel()?.toUpperCase() == 'PERFORMANCE BY PO'">
        <app-vendor-po-performance-charts></app-vendor-po-performance-charts>
    </ng-container>
</ng-container>
<ng-container *ngIf="this.selectedUserType && this.selectedUserType?.toUpperCase() == 'CUSTOMS TEAM'">
    <ng-container *ngIf="this.tabLabel()?.toUpperCase() == 'OVERALL PERFORMANCE'">
        <app-customs-team-overall-performance-charts></app-customs-team-overall-performance-charts>
    </ng-container>
</ng-container>

