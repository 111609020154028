import { CommonModule } from '@angular/common';
import { Component, effect, inject, signal } from '@angular/core';
import Highcharts from 'highcharts/highcharts-gantt';
import { HighchartsChartModule } from 'highcharts-angular';
import HC_exporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DashboardService } from '../../../../../../core/services/dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../../../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
NoDataToDisplay(Highcharts);
HC_exporting(Highcharts);

@Component({
  selector: 'app-client-dashboard-overall-performance-chart',
  standalone: true,
  imports: [
      CommonModule,
      MatCardModule,
      HighchartsChartModule,
      MatProgressSpinnerModule
  ],
  templateUrl: './overall-performance-chart.component.html',
  styleUrl: './overall-performance-chart.component.scss'
})
export class OverallPerformanceChartComponent {
  Highcharts: typeof Highcharts = Highcharts;
      chartHeight = "30vh"
      chartWidth = "100%"
      loadingSpinnerPercentageTop = "40%"
      loadingSpinnerPercentageLeft = "42%"
      darkMode:boolean = false;
      selectedDashboardTimeFrameNumberOfDays:number|undefined;
      
      dashboardService = inject(DashboardService)
      selectedUserType:string = this.dashboardService.userTypes[0];
  
      showLoadingSpinner = true;
      updateCharts = false;
  
      chartOptions = signal<Highcharts.Options>({
            chart: { type: 'pie', backgroundColor: 'transparent' },
            title: { text: 'Overall Performance by Status', align: 'left', style: { fontSize: '15px' } },
            plotOptions: {
                pie: {
                    innerSize: '45%',
                    dataLabels: { enabled: true, format: '{point.name}: {point.percentage:.1f}%', style: { fontSize: '12px' } }
                }
            },
            credits: { enabled: false },
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems:["viewFullscreen","printChart"]
                    }
                }
            },
            lang: 
            {
                noData: this.dashboardService.noDataForThisTimeFrameMessage,
            },
            noData: this.dashboardService.noDataMessageStyle
        });
  
      constructor(private dialog:MatDialog) {
          effect(() => {
            if(this.dashboardService.selectedUserType().toUpperCase()=="CLIENT" && 
            (this.darkMode != this.dashboardService.darkMode() 
            || this.selectedUserType != this.dashboardService.selectedUserType()
            || this.selectedDashboardTimeFrameNumberOfDays != this.dashboardService.selectedDashboardTimeFrameNumberOfDays())
          ) {
              this.darkMode = this.dashboardService.darkMode();
              this.selectedUserType = this.dashboardService.selectedUserType();
              this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays();
              this.getChartOptions()
            }
          })
      
              
      
        }
      
        ngOnInit(): void {
          this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays();
          this.getChartOptions()
          
        }
    
      
         getChartOptions() {
            this.updateCharts = false;
            this.showLoadingSpinner = true;
            this.dashboardService.getClientOverallPerformanceByStatusDonutChartOptions().subscribe({
              next:(data:any)=> {
                let chartOptionsWithData = this.chartOptions()
        
                    const dataWithColors = data.map((dataItem: any) => {
                        let color;
                        let y = dataItem.yAxis;
                        if (dataItem.name === 'Under Customs Review') color = "rgb(44,175,254)";
                        else if (dataItem.name === 'Approved and Classified') color = "rgb(0,226,114)";
                        else if (dataItem.name === 'Revision Required') color = 'red';
                        return { ...dataItem, color,y };
                    });
        
                    chartOptionsWithData.series = [{
                        name: 'Categories',
                        type: 'pie',
                        data: dataWithColors // Use data with specific colors
                    }]
        
                    this.chartOptions.set(chartOptionsWithData)
              },
              error:() => {
                if(!this.dashboardService.errorMessageDisplayed()) {
                  this.dashboardService.errorMessageDisplayed.set(true);
                  const errorDialog = this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
                  errorDialog.afterClosed().subscribe(() => {
                      this.dashboardService.errorMessageDisplayed.set(false)
                    }
                  );
                  
                }
              },
              complete:() => {
                this.chartOptions.set(this.dashboardService.applyChangeToDarkModeToChartTextColor(this.chartOptions()));
                this.updateCharts = true;
                this.showLoadingSpinner = false;
              }
            })
          }
}
