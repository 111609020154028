<mat-card [ngClass]="darkMode ? 'chart-card darkModeEnabled':'chart-card darkModeDisabled'">
    <mat-card-content>
        <div class="row">
            <h6 [ngStyle]="darkMode ? {'color': 'white'} : {'color': 'black'}">Performance overview by status</h6>
        </div>
        <div class="row">
            <mat-card *ngIf="vendorOverallPerformanceBulletChart1Options()" [ngClass]="darkMode ? 'chart-card darkModeEnabled':'chart-card darkModeDisabled'" >
                <mat-card-content>
                    <div *ngIf="this.showLoadingSpinner" [style.minHeight]="'130px'">
                        <div class="mat-spinner-container" [style.top]="'20%'" [style.left]="loadingSpinnerPercentageLeft">
                            <mat-spinner></mat-spinner>
                        </div>
                    </div>
                    <div *ngIf="!this.showLoadingSpinner">
                        <highcharts-chart 
                            *ngIf="vendorOverallPerformanceBulletChart1Options()"
                            [Highcharts]="Highcharts"
                            [options]="vendorOverallPerformanceBulletChart1Options()"
                            [(update)]="updateCharts"
                            [style.width]="chartWidth" 
                            [style.height]= "'130px'"
                            [style.display]="'block'"
                            ></highcharts-chart>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="row mt-2">
            <mat-card *ngIf="vendorOverallPerformanceBulletChart2Options()" [ngClass]="darkMode ? 'chart-card darkModeEnabled':'chart-card darkModeDisabled'" >
                <mat-card-content>
                    <div *ngIf="this.showLoadingSpinner" [style.minHeight]="'130px'">
                        <div class="mat-spinner-container" [style.top]="'20%'" [style.left]="loadingSpinnerPercentageLeft">
                            <mat-spinner></mat-spinner>
                        </div>
                    </div>
                    <div *ngIf="!this.showLoadingSpinner">
                        <highcharts-chart 
                            *ngIf="vendorOverallPerformanceBulletChart2Options()"
                            [Highcharts]="Highcharts"
                            [options]="vendorOverallPerformanceBulletChart2Options()"
                            [(update)]="updateCharts"
                            [style.width]="chartWidth" 
                            [style.height]= "'130px'"
                            [style.display]="'block'"
                            ></highcharts-chart>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="row mt-2">
            <mat-card *ngIf="vendorOverallPerformanceBulletChart3Options()" [ngClass]="darkMode ? 'chart-card darkModeEnabled':'chart-card darkModeDisabled'" >
                <mat-card-content>
                    <div *ngIf="this.showLoadingSpinner" [style.minHeight]="'130px'">
                        <div class="mat-spinner-container" [style.top]="'20%'" [style.left]="loadingSpinnerPercentageLeft">
                            <mat-spinner></mat-spinner>
                        </div>
                    </div>
                    <div *ngIf="!this.showLoadingSpinner">
                        <highcharts-chart 
                            *ngIf="vendorOverallPerformanceBulletChart3Options()"
                            [Highcharts]="Highcharts"
                            [options]="vendorOverallPerformanceBulletChart3Options()"
                            [(update)]="updateCharts"
                            [style.width]="chartWidth" 
                            [style.height]= "'130px'"
                            [style.display]="'block'"
                            ></highcharts-chart>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-card-content>
   
</mat-card>
