import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import Highcharts from 'highcharts/highcharts-gantt';
import Highchartsbullet from 'highcharts/modules/bullet';
import { HighchartsChartModule } from 'highcharts-angular';
import HC_exporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { DashboardService } from '../../../../../../core/services/dashboard.service';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../../../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
NoDataToDisplay(Highcharts);
HC_exporting(Highcharts);
Highchartsbullet(Highcharts);

@Component({
  selector: 'app-vendor-overall-performance-bullet-charts',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    HighchartsChartModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './vendor-overall-performance-bullet-charts.component.html',
  styleUrl: './vendor-overall-performance-bullet-charts.component.scss'
})
export class VendorOverallPerformanceBulletChartsComponent {
  Highcharts: typeof Highcharts = Highcharts;
    chartHeight = "30vh"
    chartWidth = "100%"
    loadingSpinnerPercentageTop = "40%"
    loadingSpinnerPercentageLeft = "42%"
    darkMode:boolean = false;
    dashboardService = inject(DashboardService)
    selectedUserType:string = this.dashboardService.userTypes[1];
    selectedDashboardTimeFrameNumberOfDays:number|undefined;

    showLoadingSpinner = true;
    updateCharts:boolean = false;

    vendorOverallPerformanceBulletChart1Options = signal<Highcharts.Options>(
        {
            chart: { inverted: true, marginLeft: 75, marginTop: 35, marginRight: 25, type: 'bullet', backgroundColor: 'transparent' },
            title: { text: 'Approved and Classified' },
            legend: { enabled: false },
            xAxis: {
                categories: ['<span class="hc-cat-title">Status</br>Count</span>'],
                labels: {
                    style: {
                        color: 'black' // Use default color
                    }
                }
            },
            yAxis: {
                max: 75,
                gridLineWidth: 1,
                plotBands: [{ from: 0, to: 150, color: '#dedede' }, { from: 150, to: 225, color: '#ececec' }, { from: 225, to: 300, color: '#f5f5f5' }],
                //title: null
                labels: {
                    style: {
                        color: 'black' // Use default color
                    }
                },
                title:{
                    style: {
                        color: 'black' // Use default color
                    }
                },
            },
            plotOptions: {
                series: {
                    //pointPadding: 0,
                    borderWidth: 1,
                    color: 'rgb(0,226,114)',
                    //targetOptions: { width: '150%', borderWidth: 1, borderColor: 'black' }
                }
            },
            tooltip: { pointFormat: '<b>{point.y}</b> (with target at {point.target})' },
            credits: { enabled: false },
            exporting: {
                enabled: false
            },
            lang: 
            {
                noData: this.dashboardService.noDataForThisTimeFrameMessage
            },
            noData: this.dashboardService.noDataMessageStyle
        }
      )
    vendorOverallPerformanceBulletChart2Options = signal<Highcharts.Options>(
        {
            chart: { inverted: true, marginLeft: 75, marginTop: 35, marginRight: 25, type: 'bullet', backgroundColor: 'transparent' },
            title: { text: 'Under Customs Review' },
            legend: { enabled: false },
            xAxis: {
                categories: ['<span class="hc-cat-title">Status</br>Count</span>'],
                labels: {
                    style: {
                        color: 'black' // Use default color
                    }
                },
            },
            yAxis: {
                max: 75,
                gridLineWidth: 1,
                plotBands: [{ from: 0, to: 150, color: '#dedede' }, { from: 150, to: 225, color: '#ececec' }, { from: 225, to: 300, color: '#f5f5f5' }],
                //title: null
                labels: {
                    style: {
                        color: 'black' // Use default color
                    }
                },
                title:{
                    style: {
                        color: 'black' // Use default color
                    }
                },
            },
            plotOptions: {
                series: {
                    //pointPadding: 0,
                    borderWidth: 1,
                    color: 'rgb(44,175,254)',
                    //targetOptions: { width: '150%', borderWidth: 1, borderColor: 'black' }
                }
            },
            tooltip: { pointFormat: '<b>{point.y}</b> (with target at {point.target})' },
            credits: { enabled: false },
            exporting: {
                enabled: false
            },
            lang: 
            {
                noData: this.dashboardService.noDataForThisTimeFrameMessage
            },
            noData: this.dashboardService.noDataMessageStyle
        }
      )
    vendorOverallPerformanceBulletChart3Options = signal<Highcharts.Options>(
        {
            chart: { inverted: true, marginLeft: 75, marginTop: 35, marginRight: 25, type: 'bullet', backgroundColor: 'transparent' },
            title: { text: 'Revision Required' },
            legend: { enabled: false },
            xAxis: {
                categories: ['<span class="hc-cat-title">Status</br>Count</span>'],
                labels: {
                    style: {
                        color: 'black' // Use default color
                    }
                }
            },
            yAxis: {
                max: 75,
                gridLineWidth: 1,
                plotBands: [{ from: 0, to: 150, color: '#dedede' }, { from: 150, to: 225, color: '#ececec' }, { from: 225, to: 300, color: '#f5f5f5' }],
                //title: null
                labels: {
                    style: {
                        color: 'black' // Use default color
                    }
                },
                title:{
                    style: {
                        color: 'black' // Use default color
                    }
                },
            },
            plotOptions: {
                series: {
                    //pointPadding: 0,
                    borderWidth: 1,
                    color: 'red',
                    //targetOptions: { width: '150%', borderWidth: 1, borderColor: 'black' }
                }
            },
            tooltip: { pointFormat: '<b>{point.y}</b> (with target at {point.target})' },
            credits: { enabled: false },
            exporting: {
                enabled: false
            },
            lang: 
            {
                noData: this.dashboardService.noDataForThisTimeFrameMessage
            },
            noData: this.dashboardService.noDataMessageStyle
        }
      )

      constructor(private dialog:MatDialog) {
          effect(() => {
            if(this.dashboardService.selectedUserType().toUpperCase()=="VENDOR" && 
            (this.darkMode != this.dashboardService.darkMode() 
            || this.selectedUserType != this.dashboardService.selectedUserType()
            || this.selectedDashboardTimeFrameNumberOfDays != this.dashboardService.selectedDashboardTimeFrameNumberOfDays())
          ) {
              this.selectedUserType = this.dashboardService.selectedUserType();
              this.darkMode = this.dashboardService.darkMode();
              this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays()
              this.getVendorOverallPerformanceBulletChartOptions();
            }
          })
        }
      
        ngOnInit(): void {
          this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays()
          this.getVendorOverallPerformanceBulletChartOptions();
        }
      
        getVendorOverallPerformanceBulletChartOptions() {
          this.updateCharts = false;
          this.showLoadingSpinner = true;
          this.dashboardService.getVendorOverallPerformanceBulletChartOptions().subscribe({
            next:(data:any)=> {
              let vendorOverallPerformanceBulletChart1WithData = this.vendorOverallPerformanceBulletChart1Options();
              let vendorOverallPerformanceBulletChart2WithData = this.vendorOverallPerformanceBulletChart2Options();
              let vendorOverallPerformanceBulletChart3WithData = this.vendorOverallPerformanceBulletChart3Options();
      
              vendorOverallPerformanceBulletChart1WithData.series =
                  [{
                      type: "bullet",
                      data: [
                          {
                              y: data['approvedAndClassified'],
                              target: 5,
                              targetOptions: {
                                  borderWidth:2,
                                  borderColor: 'black',
                                  height:12,
                                  width:20
                              }
                          }
                      ]
                  }];
      
              vendorOverallPerformanceBulletChart2WithData.series =
              [{
                  type: "bullet",
                  data: [
                      {
                          y: data['underCustomsReview'],
                          target: 5,
                          targetOptions: {
                              borderWidth:2,
                              borderColor: 'black',
                              height:12,
                              width:20
                          }
                      }
                  ]
              }];
      
              vendorOverallPerformanceBulletChart3WithData.series =
              [{
                  type: "bullet",
                  data: [{
                      y: data['revisionRequired'],
                      target: 5,
                      targetOptions: {
                          borderWidth:2,
                          borderColor: 'black',
                          height:12,
                          width:20
                      }
                  }]
              }];
      
              this.vendorOverallPerformanceBulletChart1Options.set(vendorOverallPerformanceBulletChart1WithData);
              this.vendorOverallPerformanceBulletChart2Options.set(vendorOverallPerformanceBulletChart2WithData);
              this.vendorOverallPerformanceBulletChart3Options.set(vendorOverallPerformanceBulletChart3WithData);
            },
            error:() => {
              if(!this.dashboardService.errorMessageDisplayed()) {
                this.dashboardService.errorMessageDisplayed.set(true);
                const errorDialog = this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
                errorDialog.afterClosed().subscribe(() => {
                    this.dashboardService.errorMessageDisplayed.set(false)
                  }
                );
                
              }
            },
            complete:() => {
              this.vendorOverallPerformanceBulletChart1Options.set(this.dashboardService.applyChangeToDarkModeToChartTextColor(this.vendorOverallPerformanceBulletChart1Options()));
              this.vendorOverallPerformanceBulletChart2Options.set(this.dashboardService.applyChangeToDarkModeToChartTextColor(this.vendorOverallPerformanceBulletChart2Options()));
              this.vendorOverallPerformanceBulletChart3Options.set(this.dashboardService.applyChangeToDarkModeToChartTextColor(this.vendorOverallPerformanceBulletChart3Options()));
              this.updateCharts = true;
              this.showLoadingSpinner = false;
            }
          })
        }

}
