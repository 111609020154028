import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { VendorOverallPerformanceBulletChartsComponent } from "./vendor-overall-performance-bullet-charts/vendor-overall-performance-bullet-charts.component";
import { CumulativePerformanceChartComponent } from "./cumulative-performance-chart/cumulative-performance-chart.component";
import { ItemAttributeChartComponent } from "./item-attribute-chart/item-attribute-chart.component";
import { ItemTypeGroupPerformanceChartComponent } from "./item-type-group-performance-chart/item-type-group-performance-chart.component";
import { ItemTypesPerformanceChartComponent } from "./item-types-performance-chart/item-types-performance-chart.component";

@Component({
  selector: 'app-vendor-overall-performance-charts',
  standalone: true,
  imports: [
    CommonModule,
    VendorOverallPerformanceBulletChartsComponent,
    CumulativePerformanceChartComponent,
    ItemAttributeChartComponent,
    ItemTypeGroupPerformanceChartComponent,
    ItemTypesPerformanceChartComponent
],
  templateUrl: './vendor-overall-performance-charts.component.html',
  styleUrl: './vendor-overall-performance-charts.component.scss'
})
export class VendorOverallPerformanceChartsComponent {  

  constructor() {
  }
}
