import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, signal } from '@angular/core';
import Highcharts from 'highcharts/highcharts-gantt';
import { HighchartsChartModule } from 'highcharts-angular';
import HC_exporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { KpiItemComponent } from './kpi-item/kpi-item.component';
import { DashboardService } from '../../../../../../core/services/dashboard.service';
import { IDashboardKpiItem } from '../../../../../../core/models/IDashboardKpiItem';
import { MatDialog } from '@angular/material/dialog';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../../../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
NoDataToDisplay(Highcharts);
HC_exporting(Highcharts);

@Component({
  selector: 'app-customs-team-kpi-items',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    KpiItemComponent,
    MatCardModule,
    HighchartsChartModule
  ],
  templateUrl: './customs-team-kpi-items.component.html',
  styleUrl: './customs-team-kpi-items.component.scss'
})
export class CustomsTeamKpiItemsComponent {
  Highcharts: typeof Highcharts = Highcharts;
  chartHeight = "30vh"
  chartWidth = "100%"
  loadingSpinnerPercentageTop = "40%"
  loadingSpinnerPercentageLeft = "42%"
  darkMode:boolean = false;
  dashboardService = inject(DashboardService)
  selectedDashboardTimeFrameNumberOfDays:number|undefined;
  selectedUserType:string = this.dashboardService.userTypes[2];
  customsTeamKpiItems = signal<IDashboardKpiItem[]|undefined>(undefined);
  showKpiCardLoadingSpinner:boolean = true;

   constructor(private dialog:MatDialog) {
      effect(() => {
          if(this.dashboardService.selectedUserType().toUpperCase()=="CUSTOMS TEAM" && 
          (this.darkMode != this.dashboardService.darkMode() 
          || this.selectedUserType != this.dashboardService.selectedUserType()
          || this.selectedDashboardTimeFrameNumberOfDays != this.dashboardService.selectedDashboardTimeFrameNumberOfDays())
        ) {
          
          this.selectedUserType = this.dashboardService.selectedUserType();
          this.darkMode = this.dashboardService.darkMode();
          this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays();
          this.getCustomTeamKpiItems();
        }
      })
    }
  
    ngOnInit(): void {
      this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays();
      this.getCustomTeamKpiItems();
    }
  
    getNoDataMessage() {
      return this.dashboardService.noDataForThisTimeFrameMessage
    }

    getCustomTeamKpiItems() {
        this.showKpiCardLoadingSpinner = true;
        this.dashboardService.getCustomTeamKpiItems().subscribe({
          next:(data:any)=> {
            let kpiItemsMappedFromResponse:IDashboardKpiItem[] = [];
            const comparisonText = 'vs. week previous';
    
            data.forEach((kpiItemResponse:any) => {
    
                const percentageChange = kpiItemResponse.percentageChange;
                const isPositive = percentageChange >= 0;
                const sign = isPositive ? '▲' : '▼';
                const percentageChangeColor = isPositive ? 'green' : 'red';
                const formattedChange = `${sign} ${Math.abs(percentageChange).toFixed(2)}%`;
                const gradientColor = this.dashboardService.statusColors[kpiItemResponse.statusName] || 'grey';
    
                const kpiItemParsedFromResponse:IDashboardKpiItem = {
                    value: kpiItemResponse.latestCount.toLocaleString(),
                    title:kpiItemResponse.statusName,
                    changePercentage:formattedChange,
                    changePercentageColor:percentageChangeColor,
                    comparisonText:comparisonText,
                    borderGradientColor:`linear-gradient(to bottom, ${gradientColor}, rgba(0, 0, 0, 0))`
                }
    
                kpiItemsMappedFromResponse.push(kpiItemParsedFromResponse);
            });
            this.customsTeamKpiItems.set(kpiItemsMappedFromResponse);
          },
          error:() => {
            if(!this.dashboardService.errorMessageDisplayed()) {
              this.dashboardService.errorMessageDisplayed.set(true);
              const errorDialog = this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
              errorDialog.afterClosed().subscribe(() => {
                  this.dashboardService.errorMessageDisplayed.set(false)
                }
              );
              
            }
          },
          complete:() => {
            this.customsTeamKpiItems.set(this.dashboardService.applyChangeToDarkModeToChartTextColor(this.customsTeamKpiItems()));
            this.showKpiCardLoadingSpinner = false;
          }
        })
      }
}
