import { CommonModule } from '@angular/common';
import { Component} from '@angular/core';
import { OverallPerformanceChartComponent } from "./overall-performance-chart/overall-performance-chart.component";
import { CumulativePerformanceChartComponent } from "./cumulative-performance-chart/cumulative-performance-chart.component";
import { ItemTypeGroupPerformanceChartComponent } from "./item-type-group-performance-chart/item-type-group-performance-chart.component";
import { ItemAttributePerformanceChartComponent } from "./item-attribute-performance-chart/item-attribute-performance-chart.component";


@Component({
  selector: 'app-client-overall-performance-charts',
  standalone: true,
  imports: [
    CommonModule,
    OverallPerformanceChartComponent,
    CumulativePerformanceChartComponent,
    ItemTypeGroupPerformanceChartComponent,
    ItemAttributePerformanceChartComponent
],
  templateUrl: './client-overall-performance-charts.component.html',
  styleUrl: './client-overall-performance-charts.component.scss'
})
export class ClientOverallPerformanceChartsComponent {


  constructor() {  
  }

  ngOnInit(): void {
  }

}
