<div class="row mt-4">
    <mat-card *ngIf="clientVendorPerformanceByStatusTop10StackedBarChartOptions()" [ngClass]="darkMode ? 'chart-card darkModeEnabled':'chart-card darkModeDisabled'" >
        <mat-card-content>
            <div *ngIf="this.showLoadingSpinner" [style.minHeight]="chartHeight">
                <div class="mat-spinner-container" [style.top]="loadingSpinnerPercentageTop" [style.left]="'48%'">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
            <div *ngIf="!this.showLoadingSpinner">
                <highcharts-chart 
                    *ngIf="clientVendorPerformanceByStatusTop10StackedBarChartOptions()"
                    [Highcharts]="Highcharts"
                    [options]="clientVendorPerformanceByStatusTop10StackedBarChartOptions()"
                    [(update)]="updateCharts"
                    [style.width]="chartWidth" 
                    [style.height]= "chartHeight"
                    [style.display]="'block'"
                    ></highcharts-chart>
            </div>
        </mat-card-content>
    </mat-card>
</div>
