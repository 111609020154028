<mat-card *ngIf="customsTeamKpiItems()" [ngClass]="darkMode ? 'chart-card darkModeEnabled':'chart-card darkModeDisabled'">
    <mat-card-content>
        <div *ngIf="this.showKpiCardLoadingSpinner" class="row mt-4" class="kpi-mat-spinner-row">
            <div class="kpi-mat-spinner-container">
                <mat-spinner></mat-spinner>
            </div>
        </div>
        <ng-container *ngIf="!this.showKpiCardLoadingSpinner">
            <div class="row" >
                <h6 [ngStyle]="darkMode ? {'color': 'white'} : {'color': 'black'}">KPI Overview</h6>
            </div>
            <div *ngIf="customsTeamKpiItems() && customsTeamKpiItems()!.length == 0" class="no-KPI-data-message-container">
                <div class="no-KPI-data-message">{{getNoDataMessage()}}</div>
            </div>
            <ng-container *ngIf="customsTeamKpiItems() && customsTeamKpiItems()!.length > 0">
                <ng-container *ngFor="let kpiItem of customsTeamKpiItems();let even=even;let i=index;">
                    <div *ngIf="even" class="row mt-2">
                        <div *ngIf="customsTeamKpiItems()![i]" class="col-md-6 col-sm-12 px-2 py-2 kpi-col">
                            <app-kpi-item
                                [kpiTitle]="customsTeamKpiItems()![i].title"
                                [kpiValue]="customsTeamKpiItems()![i].value"
                                [kpiChangePercentage]="customsTeamKpiItems()![i].changePercentage"
                                [kpiChangePercentageColor]="customsTeamKpiItems()![i].changePercentageColor"
                                [kpiComparisonText]="customsTeamKpiItems()![i].comparisonText"
                                [kpiBorderGradientColor]="customsTeamKpiItems()![i].borderGradientColor"
                                [darkMode]="darkMode"
                            ></app-kpi-item>
                        </div>
                        <div *ngIf="customsTeamKpiItems()![i+1]" class="col-md-6 col-sm-12 px-2 py-2 kpi-col">
                            <app-kpi-item
                            [kpiTitle]="customsTeamKpiItems()![i+1].title"
                            [kpiValue]="customsTeamKpiItems()![i+1].value"
                            [kpiChangePercentage]="customsTeamKpiItems()![i+1].changePercentage"
                            [kpiChangePercentageColor]="customsTeamKpiItems()![i+1].changePercentageColor"
                            [kpiComparisonText]="customsTeamKpiItems()![i+1].comparisonText"
                            [kpiBorderGradientColor]="customsTeamKpiItems()![i+1].borderGradientColor"
                            [darkMode]="darkMode"
                        ></app-kpi-item>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </mat-card-content>
</mat-card>
