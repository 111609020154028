import { CommonModule } from '@angular/common';
import { Component, effect, inject, signal } from '@angular/core';
import Highcharts from 'highcharts/highcharts-gantt';
import { HighchartsChartModule } from 'highcharts-angular';
import HC_exporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DashboardService } from '../../../../../../core/services/dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../../../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
NoDataToDisplay(Highcharts);
HC_exporting(Highcharts);

@Component({
  selector: 'app-client-dashboard-cumulative-performance-chart',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    HighchartsChartModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './cumulative-performance-chart.component.html',
  styleUrl: './cumulative-performance-chart.component.scss'
})
export class CumulativePerformanceChartComponent {
  Highcharts: typeof Highcharts = Highcharts;
      chartHeight = "30vh"
      chartWidth = "100%"
      loadingSpinnerPercentageTop = "40%"
      loadingSpinnerPercentageLeft = "48%"
      darkMode:boolean = false;
      selectedDashboardTimeFrameNumberOfDays:number|undefined;
      
      dashboardService = inject(DashboardService)
      selectedUserType:string = this.dashboardService.userTypes[0];
  
      showLoadingSpinner = true;
      updateCharts = false;
  
      chartOptions = signal<Highcharts.Options>(
            {
                chart: { type: 'spline', backgroundColor: 'transparent' },
                title: { text: 'Cumulative Performance to Date by Status', align: 'left', style: { fontSize: '15px' } },
                yAxis: { min: 0, gridLineWidth: 0, title: { text: 'Count' }, labels: { enabled: false } },
                legend: {
                    align: 'right', verticalAlign: 'top', y: 1, floating: false, backgroundColor: 'white', borderColor: '#CCC', borderWidth: 0, shadow: false,
                    itemStyle: { fontSize: '10px' }
                },
                tooltip: {
                    shared: true,
                    //crosshairs: true,
                    headerFormat: '<b>{point.key}</b><br/>'
                },
                plotOptions: { spline: { marker: { enabled: true, radius: 4, symbol: 'circle' } } },
                credits: { enabled: false },
                exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems:["viewFullscreen","printChart"]
                    }
                }
              }, 
              lang: 
              {
                  noData: this.dashboardService.noDataForThisTimeFrameMessage,
              },
              noData: this.dashboardService.noDataMessageStyle
          }
          )
  
          constructor(private dialog:MatDialog) {
            effect(() => {
              if(this.dashboardService.selectedUserType().toUpperCase()=="CLIENT" && 
              (this.darkMode != this.dashboardService.darkMode() 
              || this.selectedUserType != this.dashboardService.selectedUserType()
              || this.selectedDashboardTimeFrameNumberOfDays != this.dashboardService.selectedDashboardTimeFrameNumberOfDays())
            ) {
                this.darkMode = this.dashboardService.darkMode();
                this.selectedUserType = this.dashboardService.selectedUserType();
                this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays();
                this.getChartOptions();
              }
            })
        
                
        
          }
        
          ngOnInit(): void {
            this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays();
            this.getChartOptions();
            
          }
  
          getChartOptions() {
            this.updateCharts = false;
            this.showLoadingSpinner = true;
            this.dashboardService.getClientCumulativePerformanceByStatusLast7DaysSplineChartOptions().subscribe({
              next:(data:any)=> {
                let chartOptionsWithData = this.chartOptions()
        
                const seriesWithColors = this.dashboardService.mapSeriesWithColors(data.series);
        
                chartOptionsWithData.series = seriesWithColors;
                chartOptionsWithData.xAxis = {
                    categories: data.categories,
                    gridLineWidth: 1,
                    title: { text: 'Day' },
                    labels: { rotation: 0, align: 'right' }
                };
        
                this.chartOptions.set(chartOptionsWithData)
              },
              error:() => {
                if(!this.dashboardService.errorMessageDisplayed()) {
                  this.dashboardService.errorMessageDisplayed.set(true);
                  const errorDialog = this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
                  errorDialog.afterClosed().subscribe(() => {
                      this.dashboardService.errorMessageDisplayed.set(false)
                    }
                  );
                  
                }
              },
              complete:() => {
                this.chartOptions.set(this.dashboardService.applyChangeToDarkModeToChartTextColor(this.chartOptions()));
                this.updateCharts = true;
                this.showLoadingSpinner = false;
              }
            })
          }
}
