import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CustomsTeamKpiItemsComponent } from "./customs-team-kpi-items/customs-team-kpi-items.component";
import { CumulativePerformanceChartComponent } from "./cumulative-performance-chart/cumulative-performance-chart.component";
import { ItemAttributeChartComponent } from "./item-attribute-chart/item-attribute-chart.component";
import { ItemTypeGroupChartComponent } from "./item-type-group-chart/item-type-group-chart.component";
import { ItemTypePerformanceChartComponent } from "./item-type-performance-chart/item-type-performance-chart.component";
import { OverallPerformanceChartComponent } from "./overall-performance-chart/overall-performance-chart.component";


@Component({
  selector: 'app-customs-team-overall-performance-charts',
  standalone: true,
  imports: [
    CommonModule,
    CustomsTeamKpiItemsComponent,
    CumulativePerformanceChartComponent,
    ItemAttributeChartComponent,
    ItemTypeGroupChartComponent,
    ItemTypePerformanceChartComponent,
    OverallPerformanceChartComponent
],
  templateUrl: './customs-team-overall-performance-charts.component.html',
  styleUrl: './customs-team-overall-performance-charts.component.scss'
})
export class CustomsTeamOverallPerformanceChartsComponent {
  constructor() {
  }

  ngOnInit(): void {
  }

}
