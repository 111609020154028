<mat-card [ngStyle]="darkMode?{'background-color': 'black'} : {'background-color': 'white'}">
    <mat-card-content>
      <div class="ms-1 mt-2">
          <div class="row">

          </div>
          <div class="row">
            <div class="col-sm-12 col-md-7 mt-2">
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li *ngFor="let tabLabel of tabLabels;index as i" class="nav-item" role="presentation">
                    <button class="nav-link active" [ngClass]="{ 'active':selectedTabIndex==i}" [id]="'pills-' + i +'-order-tab'" data-bs-toggle="pill" [attr.data-bs-target]="'#pills-' + i + '-order-tab-content'" type="button" role="tab" [attr.data-index]="i" (click)="changeActiveTab($event)">
                      {{tabLabel}}
                    </button>
                </li>
              </ul>
            </div>
            <div class="col-sm-12 col-md-1 mb-2 mt-3 justify-content-center">
              <div class="form-check form-switch d-flex justify-content-center">
                <input class="form-check-input custom-control-input justify-content-center" type="checkbox" role="switch" id="darkModeSwitch" (click)="onDarkModeSwitchClickEvent()">
              </div>
            </div>
            <div class="col-sm-12 col-md-2 mb-2">
              <div class="form-floating">
                <select class="form-select" id="dashboardTimeFrameSelect" aria-label="Dashboard Time Frame Select" [formControl]="dashboardTimeFrameControl" (change)="onDashboardTimeFrameSelectChangeEvent($event)">
                  <option *ngFor="let dashboardTimeFrame of dashboardTimeFrames" [value]="dashboardTimeFrame.numberOfDays">{{dashboardTimeFrame.label}}</option>
                </select>
                <label for="dashboardTimeFrameSelect">Time Frame</label>
              </div>
          </div>
            <div class="col-sm-12 col-md-2">
                <div class="form-floating">
                  <select class="form-select" id="userTypeSelect" aria-label="User Type Time Frame Select" [(ngModel)]="this.selectedUserType" (change)="onUserTypeSelectChangeEvent()">
                    <option *ngFor="let usertype of userTypes" value="{{usertype}}">{{usertype}}</option>
                  </select>
                  <label for="userTypeSelect">User Type</label>
                </div>
            </div>
          </div>

          <div class="tab-content my-2" id="pills-tabContent">
              <div *ngFor="let tabLabel of tabLabels;index as i" class="tab-pane fade"  [ngClass]="{ 'show active':selectedTabIndex==i}" [id]="'pills-' + i +'-order-tab-content'" role="tabpanel" [attr.aria-labelledby]="'pills-' + i +'-order-tab'">
                <app-tab-item-detail
                [tabLabel]="tabLabel"
                ></app-tab-item-detail>
              </div>
          </div>
      </div>
    </mat-card-content>
  </mat-card>
