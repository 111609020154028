import { CommonModule } from '@angular/common';
import { Component, effect, input } from '@angular/core';

@Component({
  selector: 'app-kpi-item',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './kpi-item.component.html',
  styleUrl: './kpi-item.component.scss'
})
export class KpiItemComponent {
  kpiTitle = input<string|undefined>(undefined)
  kpiValue = input<string|undefined>(undefined)
  kpiChangePercentage = input<string|undefined>(undefined)
  kpiChangePercentageColor = input<string|undefined>(undefined)
  kpiComparisonText = input<string|undefined>(undefined)
  kpiBorderGradientColor = input<string|undefined>(undefined)
  darkMode= input<boolean>(false)
}
