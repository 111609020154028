export const environment = {
  production: false,
  companyName: 'Core (UK) Limited',
  companyWebUrl: 'https://www.tarifftel.com/',
  productName: 'TariffTel',
  supportEmailAddress: 'support@tarifftel.com',
  apiUrl:"https://api-test.tarifftel.com",
  msalConfig: {
    auth:
    {
      clientId: '8ee1e9be-c15f-4913-9895-24d2577e791e',
      authority: 'https://tarifftelcustoms.ciamlogin.com/',
      redirectUri: 'https://customs-test.tarifftel.com/',
      postLogoutRedirectUri: 'https://customs-test.tarifftel.com/logged-out',
    },
    readScope:"api://0a3a1e0b-1e18-41e7-8df3-fed6ee953e2b/user.write",
    writeScope:"api://0a3a1e0b-1e18-41e7-8df3-fed6ee953e2b/user.read"
  }
};
