import { Component, effect, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { TabItemDetailComponent } from './tab-item-detail/tab-item-detail.component';
import { DashboardService } from '../../../core/services/dashboard.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { TranslateModule } from '@ngx-translate/core';
import { IDashboardChartTimeFrame } from '../../../core/models/IDashboardChartTimeFrame';

@Component({
  selector: 'app-tab-list-charts',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    TabItemDetailComponent,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    ReactiveFormsModule
  ],
  templateUrl: './tab-list-charts.component.html',
  styleUrl: './tab-list-charts.component.scss'
})
export class TabListChartsComponent implements OnInit {
  selectedTabIndex = 0;
  dashboardService = inject(DashboardService)
  darkMode:boolean = false;
  selectedUserType:string | undefined;
  tabLabels:string[] | undefined;
  userTypes:string[] | undefined
  dashboardTimeFrames:IDashboardChartTimeFrame[] | undefined
  selectedDashboardTimeFrameNumberOfDays:number | undefined;
  dashboardTimeFrameControl = new FormControl();

  constructor(private authService:MsalService) {

    effect(()=> {
      this.darkMode = this.dashboardService.darkMode();
      this.selectedUserType = this.dashboardService.selectedUserType();
      this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays();
      this.updateTabLabels();
    })

  }
  ngOnInit(): void {
    this.userTypes = this.dashboardService.userTypes;
    this.dashboardTimeFrames = this.dashboardService.dashboardTimeFrames;
    this.dashboardService.darkMode.set(false);
    this.dashboardTimeFrameControl.setValue(this.dashboardService.selectedDashboardTimeFrameNumberOfDays());
    this.dashboardService.selectedUserType.set(this.dashboardService.userTypes[0]);
  }

  onUserTypeSelectChangeEvent() {
    if(this.selectedUserType) {
      this.dashboardService.selectedUserType.set(this.selectedUserType);
    }

    this.updateTabLabels();
    this.selectedTabIndex = 0;
  }

  onDashboardTimeFrameSelectChangeEvent(event:any) {
    this.dashboardService.selectedDashboardTimeFrameNumberOfDays.set(this.dashboardTimeFrameControl.value);
    //this.updateTabLabels();
    //this.selectedTabIndex = 0;
  }

  updateTabLabels() {
    if(this.selectedUserType?.toUpperCase() == "VENDOR") {
      this.tabLabels = this.dashboardService.vendorUserTabLabels;
    }
    else if(this.selectedUserType?.toUpperCase() == "CUSTOMS TEAM") {
      this.tabLabels = this.dashboardService.customsTeamTabLabels;
    }
    else {
      this.tabLabels = this.dashboardService.clientUserTabLabels;
    }

  }

  changeActiveTab(selectedTabPill: any){
    this.selectedTabIndex = selectedTabPill.currentTarget.attributes['data-index'].value;
  }

  onDarkModeSwitchClickEvent() {
    this.dashboardService.darkMode.set(!this.darkMode);
  }
}
