
<div class="row mt-4">
    <div class="col-md-6 col-sm-12">
        <app-vendor-overall-performance-bullet-charts></app-vendor-overall-performance-bullet-charts>
    </div>
    <div class="col-md-6 col-sm-12">
        <app-vendor-dashboard-cumulative-performance-chart></app-vendor-dashboard-cumulative-performance-chart>
    </div>
</div>
<div class="row mt-4">
    <div class="col-md-4 col-sm-12">
        <app-vendor-dashboard-item-type-group-performance-chart></app-vendor-dashboard-item-type-group-performance-chart>
    </div>
    <div class="col-md-4 col-sm-12">
        <app-vendor-dashboard-item-attribute-chart></app-vendor-dashboard-item-attribute-chart>
    </div>
    <div class="col-md-4 col-sm-12">
        <app-item-types-performance-chart></app-item-types-performance-chart>
    </div>
    
</div>
