import { Component, effect, HostListener, input, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientOverallPerformanceChartsComponent } from './client-overall-performance-charts/client-overall-performance-charts.component';
import { ClientVendorPerformanceChartsComponent } from './client-vendor-performance-charts/client-vendor-performance-charts.component';
import { ClientPoPerformanceChartsComponent } from './client-po-performance-charts/client-po-performance-charts.component';
import { DashboardService } from '../../../../core/services/dashboard.service';
import { VendorOverallPerformanceChartsComponent } from "./vendor-overall-performance-charts/vendor-overall-performance-charts.component";
import { VendorPoPerformanceChartsComponent } from "./vendor-po-performance-charts/vendor-po-performance-charts.component";
import { CustomsTeamOverallPerformanceChartsComponent } from "./customs-team-overall-performance-charts/customs-team-overall-performance-charts.component";

@Component({
  selector: 'app-tab-item-detail',
  standalone: true,
  imports: [
    CommonModule,
    ClientOverallPerformanceChartsComponent,
    ClientVendorPerformanceChartsComponent,
    ClientPoPerformanceChartsComponent,
    VendorOverallPerformanceChartsComponent,
    VendorPoPerformanceChartsComponent,
    CustomsTeamOverallPerformanceChartsComponent
],
  templateUrl: './tab-item-detail.component.html',
  styleUrl: './tab-item-detail.component.scss'
})
export class TabItemDetailComponent implements OnInit {
  tabLabel = input<string|undefined>(undefined);
  selectedUserType: string| undefined;
  userTypes:string[] | undefined;
  constructor(private dashboardService:DashboardService) {
    
    effect(()=> {
      
      this.selectedUserType = this.dashboardService.selectedUserType();
    })
    
  }

  ngOnInit(): void {
    this.userTypes = this.dashboardService.userTypes;
  }

  // When highcharts exits full screen mode need to reload otherwise the chart widths do not respect bootstrap grid widths
  @HostListener("document:fullscreenchange", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    // has exited
    if (!document.fullscreenElement) { 
      window.location.reload();
    }
  }
}
