
<div class="row mt-4">
    <div class="col-md-4 col-sm-12">
        <app-client-dashboard-overall-performance-chart></app-client-dashboard-overall-performance-chart>
    </div>
    <div class="col-md-4 col-sm-12">
        <app-client-dashboard-item-type-group-performance-chart></app-client-dashboard-item-type-group-performance-chart>
    </div>
    <div class="col-md-4 col-sm-12">
        <app-client-dashboard-item-attribute-performance-chart></app-client-dashboard-item-attribute-performance-chart>
    </div>
</div>
<div class="row mt-4">
    <app-client-dashboard-cumulative-performance-chart></app-client-dashboard-cumulative-performance-chart>
</div>
