import { Component, inject } from '@angular/core';
import { SecondaryNavbarComponent } from '@tarifftel/components-library';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { TabListChartsComponent } from "./tab-list-charts/tab-list-charts.component";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    SecondaryNavbarComponent,
    TabListChartsComponent,
    TranslateModule
],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {


}
