<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
    <ng-container *ngIf="isValidationError; else defaultMessage">
        <div [innerHTML]="message"></div>
    </ng-container>

    <ng-template #defaultMessage>
        <p>
            It looks like we encountered an issue while processing your request.<br/><br/>

            Please try the following:
            <br/>
            <mat-list>
                <mat-list-item>- <b>Refresh the page</b>: Sometimes a quick refresh can solve the problem.</mat-list-item>
                <mat-list-item>- <b>Check your internet connection</b>: Ensure you're connected to the internet.</mat-list-item>
                <mat-list-item>- <b>Try again later</b>: The issue might be temporary, so please try again in a few minutes.</mat-list-item>
            </mat-list>
            <br/>
            If the problem persists, please contact our support team for assistance. We're here to help!
        </p>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button type="button" [color]="'primary'" class="mx-2" (click)="onClose()">{{ buttonText }}</button>
</mat-dialog-actions>
