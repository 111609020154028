
<div class="row mt-4">
    <div  class="col-md-6 col-sm-12">
        <app-customs-team-kpi-items></app-customs-team-kpi-items>
    </div>
    <div class="col-md-6 col-sm-12">
        <app-customs-team-dashboard-overall-performance-chart></app-customs-team-dashboard-overall-performance-chart>
    </div>
</div>
<div class="row mt-4">
    <div class="col-md-4 col-sm-12">
        <app-customs-team-dashboard-item-type-group-chart></app-customs-team-dashboard-item-type-group-chart>
    </div>
    <div class="col-md-4 col-sm-12">
        <app-customs-team-dashboard-item-attribute-chart></app-customs-team-dashboard-item-attribute-chart>
    </div>
    <div class="col-md-4 col-sm-12">
        <app-item-type-performance-chart></app-item-type-performance-chart>
    </div>
</div>
<div class="row mt-4">
    <div class="col-12">
        <app-customs-team-dashboard-cumulative-performance-chart></app-customs-team-dashboard-cumulative-performance-chart>
    </div>
</div>
