<div class="kpi-card" [style.background-color]='darkMode() ? "black":"white"' [style.color]='darkMode() ? "white":"black"' [style.box-shadow]='darkMode() ? "4px 4px 12px white":"4px 4px 12px rgba(0, 0, 0, 0.2)"'>
    <div class="kpi-border" [style.background]="kpiBorderGradientColor()" ></div>
    <div class="kpi-content" >
        <p class="kpi-title" [style.background-color]='darkMode() ? "black":"white"' [style.color]='darkMode() ? "white":"black"'>{{kpiTitle()}}</p>
        <h1 class="kpi-value" [style.background-color]='darkMode() ? "black":"white"' [style.color]='darkMode() ? "white":"black"'>{{kpiValue()}}</h1>
        <div class="kpi-change">
            <span class="change-percentage" [style.color]="kpiChangePercentageColor()">{{kpiChangePercentage()}}</span>
            <span class="comparison-text" [style.background-color]='darkMode() ? "black":"white"' [style.color]='darkMode() ? "white":"black"'>{{kpiComparisonText()}}</span>
        </div>
    </div>
</div>
