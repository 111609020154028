import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Highcharts from 'highcharts/highcharts-gantt';
import { HighchartsChartModule } from 'highcharts-angular';
import HC_exporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DashboardService } from '../../../../../../core/services/dashboard.service';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../../../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
NoDataToDisplay(Highcharts);
HC_exporting(Highcharts);

@Component({
  selector: 'app-item-type-performance-chart',
  standalone: true,
  imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatCardModule,
        HighchartsChartModule,
  ],
  templateUrl: './item-type-performance-chart.component.html',
  styleUrl: './item-type-performance-chart.component.scss'
})
export class ItemTypePerformanceChartComponent {
  Highcharts: typeof Highcharts = Highcharts;
      chartHeight = "30vh"
      chartWidth = "100%"
      loadingSpinnerPercentageTop = "40%"
      loadingSpinnerPercentageLeft = "42%"
      darkMode:boolean = false;
      dashboardService = inject(DashboardService)
      selectedDashboardTimeFrameNumberOfDays:number|undefined;
      
      selectedUserType:string = this.dashboardService.userTypes[2];
      showLoadingSpinner = true;
      updateCharts = false;
  
      chartOptions = signal<Highcharts.Options>(
            {
                chart: {
                    type: 'bar',
                    backgroundColor: 'transparent'
                },
                title: {
                    text: 'Top 10 -  Performance by Item Type by Status',
                    align: 'left',
                    style: {
                        fontSize: '15px'
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Count'
                    },
                    labels: {
                        enabled: false
                    },
                    gridLineWidth: 0,
                    stackLabels: {
                        enabled: true,
                        formatter: function () {
                            return Highcharts.numberFormat(this.total, 0);
                        },
                        style: {
                            color: 'black'
                        }
                    }
                },
                legend: {
                    align: 'right',
                    verticalAlign: 'top',
                    y: 1,
                    floating: false,
                    backgroundColor: 'white',
                    itemStyle: {
                        fontSize: '10px'
                    },
                    //borderColor: null,
                    borderWidth: 0,
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
                },
                plotOptions: {
                    bar: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: true,
                    buttons: {
                        contextButton: {
                            menuItems:["viewFullscreen","printChart"]
                        }
                    }
                },
                lang: 
                {
                    noData: this.dashboardService.noDataForThisTimeFrameMessage
                },
                noData: this.dashboardService.noDataMessageStyle
            }
          )
  
          constructor(private dialog:MatDialog) {
            effect(() => {
                if(this.dashboardService.selectedUserType().toUpperCase()=="CUSTOMS TEAM" && 
                (this.darkMode != this.dashboardService.darkMode() 
                || this.selectedUserType != this.dashboardService.selectedUserType()
                || this.selectedDashboardTimeFrameNumberOfDays != this.dashboardService.selectedDashboardTimeFrameNumberOfDays())
              ) {
                
                this.selectedUserType = this.dashboardService.selectedUserType();
                this.darkMode = this.dashboardService.darkMode();
                this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays();
                this.getChartOptions();
              }
            })
          }
        
          ngOnInit(): void {
            this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays();
            this.getChartOptions();
          }
  
          getChartOptions() {
            this.showLoadingSpinner = true;
            this.updateCharts = false;
            this.dashboardService.getCustomsTeamItemTypePerformanceByStatusTop10StackedBarChartOptions().subscribe({
              next:(data:any)=> {
                let chartOptionsWithData = this.chartOptions()
        
                const seriesWithColors = this.dashboardService.applyStatusColors(data.series);
        
                chartOptionsWithData.series = seriesWithColors;
                chartOptionsWithData.xAxis = {
                    categories: data.categories,
                    title: {
                        text: 'Item Type'
                    },
                    labels: {
                        formatter: function () {
                            return (this.value as string).toUpperCase();
                        },
                        style: {
                            color: 'black',
                            fontSize: '11px'
                        }
                    },
                    gridLineWidth: 1
                },
        
                this.chartOptions.set(chartOptionsWithData)
              },
              error:() => {
                if(!this.dashboardService.errorMessageDisplayed()) {
                  this.dashboardService.errorMessageDisplayed.set(true);
                  const errorDialog = this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
                  errorDialog.afterClosed().subscribe(() => {
                      this.dashboardService.errorMessageDisplayed.set(false)
                    }
                  );
                  
                }
              },
              complete:() => {
                this.chartOptions.set(this.dashboardService.applyChangeToDarkModeToChartTextColor(this.chartOptions()));
                this.updateCharts = true;
                this.showLoadingSpinner = false;
              
              }
            })
          }
}
