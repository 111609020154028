import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatList, MatListModule } from '@angular/material/list';

@Component({
  selector: 'app-user-friendly-http-error-message-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatDialogActions,
    MatDialogTitle,
    MatDialogContent,
    MatButtonModule,
    MatListModule,
    CommonModule
  ],
  templateUrl: './user-friendly-http-error-message-dialog.component.html',
  styleUrl: './user-friendly-http-error-message-dialog.component.scss'
})
export class UserFriendlyHttpErrorMessageDialogComponent {

  title: string;
  message: string;
  buttonText: string;
  isValidationError: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UserFriendlyHttpErrorMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string; buttonText: string; isValidationError?: boolean }
  ) {
    this.title = data.title || 'Oops! Something went wrong.';
    this.message = data.message || 'An unexpected error occurred.';
    this.buttonText = data.buttonText || 'Close';
    this.isValidationError = data.isValidationError || false;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
