import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Highcharts from 'highcharts/highcharts-gantt';
import { HighchartsChartModule } from 'highcharts-angular';
import HC_exporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DashboardService } from '../../../../../../core/services/dashboard.service';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../../../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
NoDataToDisplay(Highcharts);
HC_exporting(Highcharts);

@Component({
  selector: 'app-customs-team-dashboard-overall-performance-chart',
  standalone: true,
  imports: [
      CommonModule,
          MatProgressSpinnerModule,
          MatCardModule,
          HighchartsChartModule,
  ],
  templateUrl: './overall-performance-chart.component.html',
  styleUrl: './overall-performance-chart.component.scss'
})
export class OverallPerformanceChartComponent {
Highcharts: typeof Highcharts = Highcharts;
  chartHeight = "30vh"
  chartWidth = "100%"
  loadingSpinnerPercentageTop = "40%"
  loadingSpinnerPercentageLeft = "42%"
  darkMode:boolean = false;
  dashboardService = inject(DashboardService)
  selectedDashboardTimeFrameNumberOfDays:number|undefined;
  
  selectedUserType:string = this.dashboardService.userTypes[2];

  showLoadingSpinner = true;
  updateCharts = false;

  chartOptions = signal<Highcharts.Options>(
      {
          chart: {
              type: 'pie',
              backgroundColor: 'transparent'
          },
          title: {
              text: 'Overall Performance by Status',
              align: 'left',
              style: {
                  fontSize: '15px'
              }
          },
          plotOptions: {
              pie: {
                  innerSize: '30%',
                  dataLabels: {
                      enabled: true,
                      format: '{point.name}: </br> {point.percentage:.1f}%',
                      style: {
                          fontSize: '15px'
                      }
                  }
              }
          },
          credits: {
              enabled: false
          },
          exporting: {
              enabled: true,
              buttons: {
                  contextButton: {
                      menuItems:["viewFullscreen","printChart"]
                  }
              }
          },
          lang: 
          {
              noData: this.dashboardService.noDataForThisTimeFrameMessage
          },
          noData: this.dashboardService.noDataMessageStyle
      }
    );

    constructor(private dialog:MatDialog) {
      effect(() => {
          if(this.dashboardService.selectedUserType().toUpperCase()=="CUSTOMS TEAM" && 
          (this.darkMode != this.dashboardService.darkMode() 
          || this.selectedUserType != this.dashboardService.selectedUserType()
          || this.selectedDashboardTimeFrameNumberOfDays != this.dashboardService.selectedDashboardTimeFrameNumberOfDays())
        ) {
          
          this.selectedUserType = this.dashboardService.selectedUserType();
          this.darkMode = this.dashboardService.darkMode();
          this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays();
          this.getChartOptions();
        }
      })
    }
  
    ngOnInit(): void {
      this.selectedDashboardTimeFrameNumberOfDays = this.dashboardService.selectedDashboardTimeFrameNumberOfDays();
      this.getChartOptions();
    }

    getChartOptions() {
        this.showLoadingSpinner = true;
        this.updateCharts = false;
        this.dashboardService.getCustomsTeamOverallPerformanceByStatusDonutChartOptions().subscribe({
          next:(data:any)=> {
            let chartOptionsWithData = this.chartOptions()
            const dataWithColors = data.map((dataItem: any) => {
                const color = this.dashboardService.statusColors[dataItem.name] || dataItem.color;
                let y = dataItem.yAxis;
                return { ...dataItem, color,y };
            });
            chartOptionsWithData.series = [{
            name: 'Categories',
            type: 'pie',
            data: dataWithColors
            }]
    
            this.chartOptions.set(chartOptionsWithData)
          },
          error:() => {
            if(!this.dashboardService.errorMessageDisplayed()) {
              this.dashboardService.errorMessageDisplayed.set(true);
              const errorDialog = this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
              errorDialog.afterClosed().subscribe(() => {
                  this.dashboardService.errorMessageDisplayed.set(false)
                }
              );
              
            }
          },
          complete:() => {
            this.chartOptions.set(this.dashboardService.applyChangeToDarkModeToChartTextColor(this.chartOptions()));
            this.updateCharts = true;
            this.showLoadingSpinner = false;
          }
        })
      }
}
